import * as React from 'react'
import { FoldableContainer } from '../components'
import {
  Wish,
  Content,
  Background,
  Countdown,
  Header1,
  InnerContent,
} from '../components'
import background from '../images/wishlist-background.svg'

// Define my wishes h00ray!
const wishes = [
  {
    name: 'Robotgræsslåmaskine',
    category: 'Til haven',
    price: 5000,
    salesLink: '',
  },
  {
    name: 'Stålespalier til pergola',
    category: 'Til haven',
    price: 319,
    salesLink:
      'https://www.silvan.dk/plus-staalespalier-170-cm-80-cm?id=5700-1436369',
  },
  {
    name: 'Gavekort til gartner/havehjælp',
    category: 'Til haven',
    price: undefined,
    salesLink: undefined,
  },
  {
    name: 'Låg til evatrio gryde (Ø16 cm)',
    category: 'Til konen',
    price: 200,
    salesLink:
      'https://www.evasolo.com/da/kokken/gryder-og-pander/grydelag/tallerkenlag-m-glas/201016/',
  },
  {
    name: 'Tørretumbler',
    category: 'Til konen',
    price: 5000,
    salesLink: undefined,
  },
  {
    name: 'Sonos One-højtalere (hvid). Ikke den "gamle" Sonos Play:1.',
    category: 'Til hjemmet',
    price: 1699,
    salesLink:
      'https://www.hifiklubben.dk/streaming/sonos/sonos-one-gen-2-tradlos-hojtaler/?variant=SONONEG2WH',
  },
  {
    name: 'Skrivebord til hjemmekontor - fx dette fra Ikea',
    category: 'Til hjemmet',
    price: 3000,
    salesLink:
      'https://www.ikea.com/dk/da/p/bekant-skrivebord-haeve-saenke-hvid-s69022537/',
  },
  {
    name: 'Skrivebord til hjemmekontor - fx dette',
    category: 'Til hjemmet',
    price: 2700,
    salesLink:
      'https://scanoffice.dk/shop/stockholm-skrivebord-rektangulaert-160x80cm',
  },
  {
    name: 'Løbevogn til baby. Må gerne være brugt.',
    category: 'Sport',
    price: 1200,
    salesLink: 'https://www.dba.dk/soeg/?soeg=l%C3%B8bevogn',
  },
  {
    name: 'Pn-sikringsbeslag til vinduer (gerne flere)',
    category: 'Til hjemmet',
    price: 130,
    salesLink:
      'https://www.silvan.dk/pn-sikringsbeslag-m-laas-29-114-52-pulverlakeret-hvid?id=2740-5102238',
  },
  {
    name: 'Boksershorts i god kvalitet, str. M',
    category: 'Tøj',
    price: 200,
    salesLink: undefined,
  },
  {
    name: 'Batteridreven hækkeklipper',
    category: 'Til haven',
    price: 2000,
    salesLink: undefined,
  },
]

const today = new Date()
const birthdayDate =
  today.getMonth() > 7
    ? new Date(today.getFullYear() + 1, 7, 31)
    : new Date(today.getFullYear(), 7, 31)
const christmasDate =
  today.getMonth() === 11 && today.getDate() > 24
    ? new Date(today.getFullYear() + 1, 11, 24)
    : new Date(today.getFullYear(), 11, 24)

export class Wishlist extends React.Component {
  render() {
    const categories = [...new Set(wishes.map(wish => wish.category))]

    // We'll render each category as a foldable container with a bunch of wishes inside
    return (
      <Background background={`url(${background})`}>
        <Content>
          {birthdayDate < christmasDate && (
            <Countdown targetDate={birthdayDate} event='min fødselsdag' />
          )}
          {christmasDate < birthdayDate && (
            <Countdown targetDate={christmasDate} event='jul' />
          )}
          <InnerContent>
            <Header1>Rasmus' ønsker</Header1>
          </InnerContent>
          {categories.map(category => (
            <InnerContent key={`${category}-content`}>
              <FoldableContainer
                header={category}
                initialOpen
                key={`${category}-container`}
                maxContentHeight={400}>
                <ul>
                  {wishes
                    .filter(wish => wish.category === category)
                    .map(wish => (
                      <Wish {...wish} key={wish.name} />
                    ))}
                </ul>
              </FoldableContainer>
            </InnerContent>
          ))}
        </Content>
      </Background>
    )
  }
}
